footer{
    width: 100%;
    background-color: black;
    margin-top: 100px;
    color: white;
    position: static;
    bottom: 0;
    margin-right: 0;
    margin-left: 0;
}
.footerLastline{
    display: flex;
    justify-content: center;
}
a.footerLinks{
   
 color: #7e7e7e;
 text-decoration: none;
 font-weight: 500;   
}
.footerWigitRow{
    margin: 0;
}