// .image-gallery-thumbnail{
//     height: 150px;
//     width: auto;
//     overflow: hidden;
//     display: "flex";
// }

p{
    font-size: 18px;
}
.image-gallery-thumbnail-inner{
    display: flex;
    max-height: 100px;
    box-sizing: border-box;
    overflow: hidden;
}
.image-gallery-thumbnail-image{
    height: 100px;
    flex: 1;
    background-color: #fff;
    width: 100%;
    object-fit: scale-down;
}
.image-gallery-slide-wrapper{
    height: 500px;
    max-height: 500px;
    overflow: hidden;
}
.image-gallery-slide div{
    height: 500px;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}
.image-gallery-image{
background-color: #fff;
}

.details_section{
   
    height: 100%;
    padding: 10px;
}
.details_section h1{
    font-size: 25px;
    text-align: center;
}
.single-title{
    margin-top: 10px;
}
.single-id{
    margin-top: 10px;
}
.free-text-line{
    margin-top: 10px;
}
.button-groups{
    margin-top: 10px;
}
.table-title-stitch{
    margin-top: 10px;
}
.table-stitch{
    margin-top: 5px;
}
button.download-button{
    margin-top: 10px;

}
button.adfavorit-button{
    margin-left: 5px;
    margin-top: 10px;
}